import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import UsersUrlsService from '@/services/users-urls.service'
import DateRange from '@/components/DateRange/DateRange.vue'
import UrlsDialog from '@/views/Pages/Tools/Urls/UrlsDialog/UrlsDialog.vue'
import moment from 'moment'
import EventBus from '@/util/EventBus'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'UrlsStats',
  components: { StatsTitle, DateRange, UrlsDialog, HeaderTopDashboard },
  data: function () {
    return {
      dataStoreUrls: {},
      show: false,
      loading: true,
      options: {},
      content: [],
      searchTerm: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      total: 0,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        url: '',
        previewTitle: '',
        previewDescription: '',
        previewUrl: '',
      },
      defaultItem: {
        name: '',
        url: '',
        previewTitle: '',
        previewDescription: '',
        previewUrl: '',
      },
      data: [],
      ready: true,
      debouncedInput: undefined,
      timeout: undefined,
      startDate: undefined,
      endDate: undefined,
      editUrlId: undefined,
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('Id'), align: 'start', value: 'id' },
        { text: this.$t('Nombre'), align: 'start', value: 'name' },
        { text: this.$t('Título'), align: 'start', value: 'previewTitle' },
        { text: this.$t('Descripción'), align: 'start', value: 'previewDescription' },
        { text: this.$t('URL'), align: 'start', value: 'url' },
        { text: this.$t('Imagen'), align: 'start', value: 'previewUrl' },
        { text: this.$t('Acciones'), value: 'actions', sortable: false },
      ]
    },
    formTitle () {
      return this.editedIndex === -1 ? this.$t('Nueva URL') : this.$t('Editar Url')
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
        startDate: this.startDate,
        endDate: this.endDate,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
    isValidUrl () {
      return this.editedItem.previewUrl ? this.editedItem.previewUrl.length && this.isValidHttpUrl(this.editedItem.previewUrl) : false
    },
  },
  watch: {
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },
  methods: {
    onLoading (value) {
      this.loading = value
    },
    selectDates (dates) {
      this.startDate = dates[0]
      this.endDate = dates[1]
      this.getDataFromApi()
    },
    dateFormat (date) {
      return moment(String(date)).format('YYYY/MM/DD')
    },
    initialize (data) {
      this.content = data.map((x) => {
        return {
          id: x.id,
          name: x.name,
          url: x.url,
          previewTitle: x.preview_title,
          previewDescription: x.preview_description,
          previewUrl: x.preview_url,
          createdAt: x.created_at,
          updatedAt: x.updated_at,
        }
      })
    },
    getDataFromApi () {
      EventBus.$emit('showLoading', true)
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        startDate: this.startDate,
        endDate: this.endDate,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }

      this.content = []
      this.loading = true
      UsersUrlsService.getChannelDatatable(params)
        .then(
          (response) => {
            this.data = JSON.parse(JSON.stringify(response.data))
            this.initialize(response.data)
            this.itemsPerPage = parseInt(response.per_page)
            this.page = response.current_page
            this.total = response.total
            this.checkEdit()
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },
    checkEdit () {
      if (this.editUrlId) {
        const id = this.editUrlId
        const item = this.content.filter(item => item.id === +id)[0] || null
        if (item) {
          this.editItem(item)
        }
        this.editUrlId = undefined
      }
    },

    editItem (item) {
      this.editedIndex = this.content.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.content.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.content.splice(this.editedIndex, 1)
      this.loading = true
      UsersUrlsService.deleteDataForm(this.editedItem)
        .then(
          (response) => {
            this.getDataFromApi()
          },
          () => { },
        )
        .finally(() => {
          this.loading = false
        })
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    isValidHttpUrl (str) {
      if (!str.trim().length) {
        return false
      }

      let url
      try {
        url = new URL(str)
      } catch (_) {
        return false
      }

      return url.protocol === 'http:' || url.protocol === 'https:'
    },
  },
  created () {
    this.editUrlId = this.$route.query.edit_id
  },
}
